
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>update Site</h2>
    <form [formGroup]="siteForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="projectName">Project Name<span class="requried-field">*</span></label>
<input
          type="text"
          id="projectName"
          placeholder="Enter projectName"
          class="form-control"
          formControlName="projectName"
          [ngClass]="{ 'is-invalid': submitted && f['projectName'].errors }"
        />
          <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
            <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
          </div>
        </div>
      
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="siteNo">Site No<span class="requried-field">*</span></label>
            <input type="text"
            id="siteNo"
            placeholder="Enter siteNo"
            class="form-control"
            formControlName="siteNo"
            [ngClass]="{ 'is-invalid': submitted && f['siteNo'].errors }"
          />
            <div *ngIf="submitted && f['siteNo'].errors" class="invalid-feedback">
              <div *ngIf="f['siteNo'].errors['required']">Site No is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="squareFeet">Square Feet<span class="requried-field">*</span></label>
            <input
              type="text"
              id="squareFeet"
              placeholder="Enter squareFeet"
              class="form-control"
              formControlName="squareFeet"
              [ngClass]="{ 'is-invalid': submitted && f['squareFeet'].errors }"
            />
            <div *ngIf="submitted && f['squareFeet'].errors" class="invalid-feedback">
              <div *ngIf="f['squareFeet'].errors['required']">Square feet is required</div>
            </div>
        </div>
       
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="landCost">Land Cost<span class="requried-field">*</span></label>
            <input
              type="text"
              id="landCost"
              placeholder="Enter landCost"
              class="form-control"
              formControlName="landCost"
              [ngClass]="{ 'is-invalid': submitted && f['landCost'].errors }"
            />
            <div *ngIf="submitted && f['landCost'].errors" class="invalid-feedback">
              <div *ngIf="f['landCost'].errors['required']">Land Cost is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="buildingCost">Building Cost<span class="requried-field">*</span></label>
            <input
              type="text"
              id="buildingCost"
              placeholder="Enter buildingCost"
              class="form-control"
              formControlName="buildingCost"
              [ngClass]="{ 'is-invalid': submitted && f['purchaseDate'].errors }"
            />
            <div *ngIf="submitted && f['buildingCost'].errors" class="invalid-feedback">
              <div *ngIf="f['buildingCost'].errors['required']">Building Cost is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
          <label for="actualCost">Actual Cost<span class="requried-field">*</span></label>
          <input
            type="text"
            id="actualCost"
            placeholder="Enter actualCost"
            class="form-control"
            formControlName="actualCost"
            [ngClass]="{ 'is-invalid': submitted && f['actualCost'].errors }"
          />
          <div *ngIf="submitted && f['actualCost'].errors" class="invalid-feedback">
            <div *ngIf="f['actualCost'].errors['required']">Actual Cost is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-4">
            <label for="siteStatus">Site Status<span class="requried-field">*</span></label>
            <select
                    id="siteStatus"
                    name="siteStatus"
                    class="form-control"
                    formControlName="siteStatus"
                    [ngClass]="{ 'is-invalid': submitted && f['siteStatus'].errors }"
                  >
                  <option value="" selected disabled>--Please select--</option>
                    <option
                      *ngFor="let data of siteStatusData"
                      [value]="data"
                      >{{ data}}
                    </option>
                  </select>
            <div *ngIf="submitted && f['siteStatus'].errors" class="invalid-feedback">
              <div *ngIf="f['siteStatus'].errors['required']">Site Status is required</div>
            </div>
          </div>
      </div>
  

      
  
      <div class="formbutton">

     
        <button type="submit" class="btn btn-primary" (click)="onSubmit(siteForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  