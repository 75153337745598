import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { DataService } from '../dataService';
import { NotificationService } from '../../../services/notificationService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  siteStatusData: string[] = ['On-going','Started','Completed','Stopped',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  keyword1="projectName";
  siteNoData:any[]=[];
  siteData:any[]=[];
  getSite:any[]=[];
  projectName:any[]=[];
  siteForm: FormGroup = new FormGroup({});
  submitted: boolean = false;

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router
  ) {}

  ngOnInit()  {
    this.siteForm= this.formBuilder.group({
      projectName: ['', Validators.required],
      siteNo: ['', Validators.required],
      squareFeet: ['',Validators.required],
      landCost: ['',Validators.required],
      buildingCost: ['',Validators.required],
      actualCost: ['', Validators.required],
      siteStatus: [''],
     
      
    });
 
  }

  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
   
    this.siteForm.patchValue({
      projectName:item.projectName,
    })
    this.data.getProject().subscribe((val:any)=>{
      this.getSite = Object.keys(val).map(key => ({ id: key, ...val[key] }));
       this.getSite.forEach((val:any)=>{
        console.log(val,"val")
        if(val.projectName.toLowerCase() == item.projectName.toLowerCase()){
        this.siteNoData.push(val.noofsite)
        console.log(this.siteNoData)
        }
       })
      
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProject().subscribe((val:any)=>{
      this.projectName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      console.log(this.projectName)
      return this.projectName.filter(val => val.productName.toLowerCase().includes(search))
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }

  goBack() {
    this.router.navigate(['/dashboard/site']);
  }
 
  get f() {
    return this.siteForm.controls;
  }

  onSubmit(data: any) {
    if (this.siteForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn=moment().format()
    data.isActive = true;

    this.data.create(data).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/site'])
    })
  }
  onCancel(){
    this.siteForm.reset()
  }

}
