<div class="container-fluid">
    <h3>Filters</h3>
    <div class="filters">
        
        <label for="supplier">Project</label>
        <input type="text" class="dropdown"/>
        <label for="supplier">Site No</label>
        <input type="text" class="dropdown"/>
        <label for="category">Site Status</label>
        
        <select id="category" class="dropdown">
            <!-- Add options here -->
        </select>
        <button id="addButton" class="btn btn-primary add-button" (click)="add()">Add +</button>
    </div>
    <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Project Name</th>
        <th>Site No</th>
        <th>Square Feet</th>
        <th>Cost</th>
        <th>Site Status</th>
        <th>Action</th>
        
        </tr>
        </thead>
        <tbody >
            <tr *ngFor="
            let element of siteList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.projectName}}</td>
              <td>{{element.siteNo}}</td>
              <td>{{element.squareFeet}}</td>
              <td>{{element.actualCost}}</td>
              <td>{{element.siteStatus}}</td>
              
              <td>
              
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
               
              </td>
             
            </tr>
            </tbody>
            </table>
     </div>
</div>